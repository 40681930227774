import React, { useState, useEffect } from "react"

export default function AddToBag({ ecwidId, classNames, product, quantity, options, inStock, unlimited }) {
  const [loading, setLoading] = useState(false)
  const [stock, setStock] = useState(0)
  const [onCart, setOnCart] = useState(null)

  console.log("inStock", inStock)
  console.log("stock", stock)


  let formatedOptions = {}
  if (options?.length) {
    options.forEach(({ name, value }) => {
      formatedOptions[name] = value
    })
  }

  function handleonCartChanged(cartItems) {
    const getQuantity = cartItems.items.filter(item => item.product.id === ecwidId).map(item => item.quantity).reduce((a,b) => a+b,0);
    const onCart = getQuantity ? getQuantity : 0
    const stockLeft = inStock - onCart;
    setStock(stockLeft)
    console.log("onCart", onCart)
    setOnCart(onCart)

  }

  function handleGetCart() {
    let interval;
    interval = setInterval(() => {
      if(window.Ecwid) {
        window.Ecwid.OnAPILoaded.add(function() {
          window.Ecwid.Cart.get(function(cart) {
            handleonCartChanged(cart)
            clearInterval(interval);
          });  
        })
        
      }
      else {
        console.log("wait")
      }
    }, 4000)
  }

  const handleGoCheckout=()=> {
    try {
      window.Ecwid.Cart.gotoCheckout(function(){
        console.log("checkout process starting...")
      });
    } catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleGetCart()
  },[])


  useEffect(() => {
    try {
     window.Ecwid.OnCartChanged.add(function(cart){
       handleonCartChanged(cart)
     });   
    }
    catch(err) {
      console.log(err);
    }
  }, [stock])

  // console.log("stock", cart)


  function handleAddToBag() {
    const productConfig = {
      id: ecwidId,
      quantity: quantity,
      options: formatedOptions,
      callback: function (success, product, cart) {
        setLoading(false)
        // localStorage.setItem("ec_current_cart", JSON.stringify(cart))
      },
    }

    try {
      setLoading(true)
      setTimeout(() => {
        window.Ecwid.OnAPILoaded.add(function () {
          window.Ecwid.Cart.addProduct(productConfig)
        })
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {!product.inStock ? (
        <p className="mt-3 text-danger">Out of Stock</p>
      ) : (
        <p className="mt-3">In stock {inStock ? ": " + (stock) + " available" : unlimited ? ": Yes" : ""}</p>
      )}

      <div className="row">
        <div className="col-12">
          {stock <= 0 && product.inStock && !unlimited ? (
            <button
              className={classNames || ""}
              onClick={handleGoCheckout}
            >Go to checkout</button>
          ) : (
            <button
              className={classNames || ""}
              onClick={handleAddToBag}
              disabled={loading || (unlimited === true ? !unlimited : !product.inStock || !stock)}
            >
              {`${loading ? "Adding" : "Add"} to Bag`}
            </button>
          )}
        </div>
        <div className="col-12">
          {onCart > 0 && (stock > 0 || stock < 0)  ? (
            <button
              className={classNames || ""}
              onClick={handleGoCheckout}
            >Go to checkout</button>
          ) : ""}
        </div>
      </div>
    </>
  )
}