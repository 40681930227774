import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { currencyFormat } from "../../utils/currencyFormatter"

const slugify = require("slugify")

const ProductOptions = props => {
  const [pOptions, setPOptions] = useState([])
  switch (props.type) {
    case "RADIO":
      return <RadioType {...props} />
    case "SELECT":
      return <OptionSelect {...props} />
    case "CHECKBOX":
      return <CheckboxType {...props} />
    case "TEXTFIELD":
      return <TextType {...props} />
    case "TEXTAREA":
      return <TextAreaType {...props} />
    default:
      return null
  }
}

const createSlug = text => {
  return slugify(text, {
    replacement: "-", // replace spaces with replacement
    remove: /[*+~.()'"!:@?]/g, // regex to remove characters
    lower: true, // result in lower case
  })
}

const TextType = ({ name, choices, setProductOptions, productOptions }) => {
  const handleTextInput = value => {
    setProductOptions(prev =>
      prev.map(el => (el.name === name ? { ...el, value: value } : el))
    )
  }

  return (
    <input
      className="form-control"
      type="text"
      name={name}
      id={createSlug(name)}
      onChange={e => handleTextInput(e.target.value)}
      required
    />
  )
}

const TextAreaType = ({ name, choices, setProductOptions, productOptions }) => {
  const handleTextInput = value => {
    setProductOptions(prev =>
      prev.map(el => (el.name === name ? { ...el, value: value } : el))
    )
  }

  return (
    <textarea
      className="form-control"
      name={name}
      id={createSlug(name)}
      onChange={e => handleTextInput(e.target.value)}
      required
    />
  )
}

const OptionSelect = ({ name, choices, setProductOptions, productOptions }) => {
  const handleOptionChange = value => {
    const selected = choices.filter(el => el.text === value)
    setProductOptions(prev =>
      prev.map(el =>
        el.name === name
          ? {
              ...el,
              value: value,
              priceModifier: selected[0]?.priceModifier || 0,
            }
          : el
      )
    )
  }

  return (
    <select
      id={createSlug(name)}
      className="form-control"
      onChange={e => handleOptionChange(e.target.value)}
    >
      {choices?.map((choice, index) => (
        <option value={choice.text} key={index}>
          {choice.priceModifier
            ? `${choice.text} (+${currencyFormat.format(choice.priceModifier)})`
            : choice.text}
        </option>
      ))}
    </select>
  )
}

const RadioType = ({ name, choices, setProductOptions, productOptions }) => {
  return (
    <div>
      {choices.map((choice, index) => {
        const selected = productOptions.find(el => el.name === name)

        const handleOptionRadio = e => {
          const { name, value } = e.target
          setProductOptions(prev =>
            prev.map(el => (el.name === name ? { ...el, value } : el))
          )
        }

        return (
          <div className="d-flex align-items-center mb-3" key={index}>
            <input
              className="form-control"
              type="radio"
              name={name}
              id={createSlug(choice.text)}
              value={choice.text}
              checked={selected?.value === choice.text}
              onChange={e => handleOptionRadio(e)}
            />
            <label
              className="form-check-label pl-3"
              htmlFor={createSlug(choice.text)}
            >
              {choice.text}
            </label>
          </div>
        )
      })}
    </div>
  )
}

const CheckboxType = ({ name, choices, setProductOptions, productOptions }) => {
  return (
    <div>
      {choices.map((choice, index) => {
        const selected = productOptions.find(el => el.name === name)
        const handleOptionChecked = e => {
          const { name, value, checked } = e.target
          setProductOptions(prev =>
            prev.map(el => {
              if (el.name !== name) return el
              if (checked) {
                return { ...el, value: [...el.value, value] }
              } else {
                return {
                  ...el,
                  value: el.value.filter(val => val !== value),
                }
              }
            })
          )
        }
        return (
          <div className="form-check" key={index}>
            <input
              className="form-control"
              type="checkbox"
              name={name}
              id={createSlug(choice.text)}
              value={choice.text}
              checked={selected?.value.includes(choice.text)}
              onChange={e => handleOptionChecked(e)}
            />
            <label
              className="form-check-label"
              htmlFor={createSlug(choice.text)}
            >
              {choice.text}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default ProductOptions
