import React, { useState, useEffect } from "react"
import Layout from "../layouts"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"
import "swiper/dist/css/swiper.min.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Category from "../components/ecwid/category"
import ProductOptions from "../components/ecwid/productOptions"
import AddToBag from "../components/ecwid/addToBag"
import { currencyFormat } from "../utils/currencyFormatter"
const slugify = require("slugify")

const ProductTemplate = ({ data, pageContext, location }) => {
  const createSlug = text => {
    return slugify(text, {
      replacement: "-", // replace spaces with replacement
      remove: /[*+~.()'"!:@?]/g, // regex to remove characters
      lower: true, // result in lower case
    })
  }

  const { id, name, seoDescription, url } = pageContext

  const ecwidCategory = data.allEcwidCategories.nodes

  const product = data.allSitePage.edges[0].node.context
  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    speed: 800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    navigation: {
      nextEl: "#product-gallery-next",
      prevEl: "#product-gallery-prev",
    },
  }

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 5,
    touchRatio: 0.2,
    slideToClickedSlide: true,
  }

  const [productOptions, setProductOptions] = useState([])
  const [productCombination, setProductCombination] = useState(null)
  const [quantity, setQuantity] = useState(1)
  console.log("productCombination", productCombination)

  useEffect(() => {
    if (product?.options?.length) {
      let _options = []
      product.options.forEach(option => {
        if (option.type === "CHECKBOX") {
          _options.push({
            name: option.name,
            value: [option.choices[option?.defaultChoice || 0].text],
          })
        } else if (option.type === "TEXTFIELD") {
          _options.push({
            name: option.name,
          })
        } else if (option.type === "TEXTAREA") {
          _options.push({
            name: option.name,
          })
        } else {
          _options.push({
            name: option.name,
            value: option.choices[option?.defaultChoice || 0].text,
            priceModifier:
              option?.choices[option?.defaultChoice || 0]?.priceModifier || 0,
          })
        }
      })
      setProductOptions(_options)
    }
  }, [])

  useEffect(
    () => {
      if (productOptions) {
        if (product?.combinations?.length) {
          setProductCombination(null)

          for (let index = 0; index < product.combinations.length; index++) {
            const combi = product.combinations[index]
            let thisIsIt = false

            for (let index2 = 0; index2 < combi.options.length; index2++) {
              const element = combi.options[index2]

              const x = productOptions.find(
                opt => opt.name === element.name && opt.value === element.value
              )

              if (!x) {
                thisIsIt = false
                break
              }

              thisIsIt = true
            }

            if (thisIsIt) setProductCombination(combi)
          }
        }
      }
    },
    [productOptions]
  )

  useEffect(
    () => {
      if (
        gallerySwiper !== null &&
        gallerySwiper.controller &&
        thumbnailSwiper !== null &&
        thumbnailSwiper.controller
      ) {
        gallerySwiper.controller.control = thumbnailSwiper
        thumbnailSwiper.controller.control = gallerySwiper
      }
    },
    [gallerySwiper, thumbnailSwiper]
  )

  let totalprice = product?.price || 0
  productOptions.forEach(opt => {
    if (opt.priceModifier) {
      totalprice += opt.priceModifier
    }
  })

  return (
    <Layout
      pathname={url}
      head={{
        name: name,
        meta: {
          description: seoDescription,
        },
      }}
    >
      <div className="solid-header-page-spacer" />
      <div className="product-page">
        <div className="container">
          <ol className="small breadcrumb justify-content-center justify-content-md-start border-bottom bg-transparent">
            <li className="breadcrumb-item">
              <Link to="/">
                <span>Home</span>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/shop">
                <span>Shop</span>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/" aria-current="page" className="btn-link disabled">
                <span>{product.name}</span>
              </Link>
            </li>
          </ol>

          <div className="row py-5">
            <div className="col-sm-4 col-lg-3 mb-4 mb-lg-0">
              <Category data={ecwidCategory} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <h1 className="font-weight-bold text-center text-md-left mb-4">
                {product.name}
              </h1>
              <div className="product-content">
                <div className="row">
                  <div className="col-lg-7 pr-md-4 mb-4 mb-md-0">
                    {gallerySwiper !== null ? (
                      <LazyLoadComponent>
                        <div className="product-gallery-swiper mb-3">
                          <Swiper {...gallerySwiperParams}>
                            {product.media.images.map(photo => (
                              <div
                                className="product-gallery-slide"
                                style={{
                                  backgroundImage: `url(${
                                    photo.imageOriginalUrl
                                  } )`,
                                }}
                                key={photo.id}
                              />
                            ))}
                          </Swiper>

                          <div className="product-gallery-nav">
                            <div
                              id="product-gallery-next"
                              className="swiper-button-next"
                              tabIndex="0"
                              role="button"
                              aria-label="Next slide"
                            />

                            <div
                              id="product-gallery-prev"
                              className="swiper-button-prev"
                              tabIndex="0"
                              role="button"
                              aria-label="Previous slide"
                            />
                          </div>
                        </div>

                        <div className="product-thumbs-swiper-wrapper">
                          <Swiper {...thumbnailSwiperParams}>
                            {product.media.images.map(photo => (
                              <div
                                className="thumbnail-slide"
                                style={{
                                  backgroundImage: `url(${
                                    photo.imageOriginalUrl
                                  })`,
                                }}
                                key={photo.id}
                              />
                            ))}
                          </Swiper>
                        </div>
                      </LazyLoadComponent>
                    ) : (
                      <img
                        className="img-fluid"
                        src={
                          product.imageUrl
                            ? product.imageUrl
                            : "https://dummyimage.com/600x400/000/fff"
                        }
                        title={product.name}
                        alt={product.name}
                        itemProp="image"
                      />
                    )}
                  </div>
                  <div className="col-lg-5 pl-md-4">
                    <div className="product-form-wrapper">
                      <div className="w-100 py-3 d-flex align-items-center justify-content-start bg-white">
                        <h3 className="configured-price font-weight-bolder">
                          {productCombination
                            ? currencyFormat.format(productCombination?.price)
                            : currencyFormat.format(totalprice)}
                        </h3>
                      </div>

                      {product.options !== null
                        ? product.options.map((option, index) => (
                            <div className="form-group" key={index}>
                              <div className="form-row align-items-start">
                                <div className="col-12 mb-2 mb-md-0">
                                  <label
                                    className="col-form-label"
                                    htmlFor={createSlug(option.name)}
                                  >
                                    {option.name}
                                  </label>
                                </div>
                                <div className="col-12">
                                  <ProductOptions
                                    {...option}
                                    setProductOptions={setProductOptions}
                                    productOptions={productOptions}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}

                      {product.quantity > 1 || product.unlimited ? (
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-auto">
                              <label
                                className="col-form-label"
                                htmlFor="quantity"
                              >
                                Quantity
                              </label>
                            </div>
                            <div className="col-4 col-sm-3 col-lg-4">
                              <input
                                type="number"
                                className="form-control"
                                min="1"
                                value={quantity}
                                onChange={e => setQuantity(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="border-top mt-4">
                        <AddToBag
                          classNames="w-100 btn blue-btn mb-3 text-white"
                          product={product}
                          quantity={quantity}
                          options={productOptions}
                          ecwidId={id}
                          inStock={product.quantity}
                          unlimited={product.unlimited}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="py-5 product-description">
                      <div className="container">
                        <h2 className="page-content-h2">About This Product</h2>
                        {product?.description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.description,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ProductTemplate

export const productQuery = graphql`
  query productQuery($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            sku
            thumbnailUrl
            quantity
            unlimited
            inStock
            name
            price
            priceInProductList
            defaultDisplayedPrice
            defaultDisplayedPriceFormatted
            isShippingRequired
            weight
            url
            created
            updated
            createTimestamp
            updateTimestamp
            productClassId
            enabled
            warningLimit
            fixedShippingRateOnly
            fixedShippingRate
            defaultCombinationId
            imageUrl
            smallThumbnailUrl
            hdThumbnailUrl
            originalImageUrl
            description
            defaultCategoryId
            seoTitle
            seoDescription
            showOnFrontpage
            isSampleProduct
            googleItemCondition
            isGiftCard
            discountsAllowed
            nameYourPriceEnabled
            productCondition
            options {
              type
              name
              defaultChoice
              required
              choices {
                text
                priceModifier
                priceModifierType
              }
              nameTranslated {
                en
              }
            }
            categoryIds
            categories {
              id
              enabled
            }
            galleryImages {
              url
              thumbnailUrl
              thumbnail
              hdThumbnailUrl
              height
              id
              imageUrl
              orderBy
              originalImageUrl
              smallThumbnailUrl
              width
            }
            media {
              images {
                id
                image1500pxUrl
                image160pxUrl
                image800pxUrl
                image400pxUrl
                orderBy
                isMain
                imageOriginalUrl
              }
            }
            relatedProducts {
              relatedCategory {
                categoryId
                enabled
                productCount
              }
            }
            originalImage {
              url
            }
            nameTranslated {
              en
            }
            dimensions {
              height
              length
              width
            }
            descriptionTranslated {
              en
            }
            tax {
              defaultLocationIncludedTaxRate
              taxable
            }
            shipping {
              type
              methodMarkup
              flatRate
              enabledMethods
              disabledMethods
            }
            combinations {
              options {
                name
                value
                valueTranslated {
                  en
                }
              }
              combinationNumber
              id
              inStock
              price
              quantity
              unlimited
              warningLimit
              weight
              defaultDisplayedPriceFormatted
              defaultDisplayedPrice
            }
          }
        }
      }
    }
    allEcwidCategories {
      nodes {
        ecwid_id
        id
        parentId
        name
        url
      }
    }
  }
`
